const data = {
    "main": {
      "name": "Mohammed",
      "occupation": "My Goal",
      "description": "Is To Obtain a Dynamic, Challenging Opportunity That Contributes To The Outstanding Success Of The Business Via 8+ Years Information Technology Experience From Various Global Technologies .",
      "image": "me.jpg",
      "bio": "To Make Use Of My Interpersonal Skills To Achieve Goals Of a Company That Focuses On Customer Satisfaction and Customer Experience .",
      "contactmessage": "For Security Reasons  - Contact Me with ( Native Call - Email - Whatsapp ) ",
      "email": "info@tallassoft.com",
      "phone": "+971 50 1944 609",
      "address": {
        "street": "",
        "city": "UAE",
        "state": "Dubai",
        "zip": "Dubai"
      },
      "website": "/",
      "resumedownload": "resume.pdf",
      "social": [
    
        {
          "name": "twitter",
          "url": "http://twitter.com/it_stackback",
          "className": "fa fa-twitter"
        },
        
       
        {
          "name": "github",
          "url": "http://github.com/ousta2",
          "className": "fa fa-github"
        },
        {
          "name": "Whatsapp",
          "url": " https://wa.me/+971501944609",
          "className": "fa fa-facebook"
        }
      ]
    },
    "resume": {
      "skillmessage": "",
      "education": [
        {
          "school": "Sudan University",
          "degree": "Bsc Information Technology",
          "graduated": "Dec 2015",
          "description": "Programming Methods ,Internet Technologies ,Data Structure ,System Analysis        and Design, Database and Database Applications ,Multimedia        Systems ,Distributed System ,Software Engineering ,Simulation and        Modeling ,Operating System ,Computer Networks ,Artificial Intelligence,        Computer Security & Human – Computer Interaction ."
        },
        {
          "school": "",
          "degree": "Technical Diploma Telecommunication Enginerring",
          "graduated": "March 2010",
          "description": "Computer Fundamental , Electronic Fundamentals , Digital Circuit Design ,        Electromagnetic Waves ,Microprocessors , Analog Telecommunication ,Computer        Interfacing , Electronics Design ,Power Electronics , Digital Signal Processor ,        Digital Communication , Programmable Logic Controller & Microcontroller ,        Communication Electronics , Digital Switching , Antennas and Wave        Propagation , Computer and Data Communication , Optical Fiber       Communication , VLSI Systems Technology , Satellite Communication & Mobile    Communication ."
        }
      ],
      "work": [
        {
          "company": "Alresala For Developed Projects Co.LTD",
          "title": "Programmer.",
          "years": "August 2013 - May 2017",
          "description": "Building an ERP System with (PHP, MYSQL, JQUERY, BOOTSTRAP, CSS,Linux), Maintain ERP System and Server , Database Backup &  Installing Small Computers Network."
        },
        {
          "company": "Civil Aviation Authority",
          "title": "",
          "years": "September 2010 - November 2010",
          "description": "Install Switch(Cisco,D-link,Nortel),Fix Trunks & Maintains Servers and  Network."
        },
        {
          "company": "Sudatel Telecommunication Company –  Sudaservice",
          "title": "",
          "years": "July 2009",
          "description": "Power Networks, Fiber Optic Maintenance – ODF & OTDR Devices, BTSs   (CDMA) Maintenance & Satellites Ground Stations (Intelsat - Arabsat –  Sudusat)."
        }
      ],
      "skills": [
        {
          "name": "Git",
          "level": "70%"
        },
        {
          "name": "PHP & MySql",
          "level": "70%"
        },
        {
          "name": "Nodejs & Mongodb",
          "level": "70%"
        },
        {
          "name": "ReactJs",
          "level": "80%"
        },
        {
          "name": "CSS",
          "level": "90%"
        },
        {
          "name": "HTML5",
          "level": "90%"
        },
        {
          "name": "VueJs",
          "level": "80%"
        },
        {
          "name": "Docker",
          "level": "70%"
        },
        {
          "name": "Linux",
          "level": "70%"
        },
       
        {
          "name": "Embedded System",
          "level": "60%"
        }
      ]
    },
    "portfolio": {
      "projects": [
        {
          "title": "Full Stack Engineer",
          "category": "E-Commerce APPs , Customers – Dashboard - Delivery Man",
          "image": "rufaa.jpg",
          "url": "#portfolio"
        },
        {
          "title": "Full Stack Developer",
          "category": "Accounting and Real State Management APPs",
          "image": "fury-fighting-gear.jpg",
          "url": "#portfolio"
        },
        {
          "title": "Embedded Engineer",
          "category": "Falcon Racing Sensor  Also  Used Laser  Tecnology",
          "image": "sensor.jpg",
          "url": "#portfolio"
        },
        {
          "title": "CCTV Supply & Installation",
          "category": "Hikvision - Dahua - Prolab",
          "image": "cctv.jpg",
          "url": "#portfolio"
        }
      ]
    },
    "testimonials": {
      "testimonials": [
        {
          "text": "MOST PROUD OF ",
          "user": "My Falcon Racing Sensor Devices Used By Some Most Powerful People In UAE (Abu Dhabi - Dubai ) ."
        },
        {
          "text": "Courses",
          "user": "CCNA , Very Small Aperture Terminal (VSAT) , +20 Course From Udemy ."
        }
      ]
    }
  }

  export default data;
  